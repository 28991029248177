import React, { useEffect, useState, useCallback } from 'react';
import apiClient from '../../services/apiClient';
import KapperAlertCard from '../../components/cards/KapperAlertCard/KapperAlertCard';
import './AlertFeed.css';

function KapperAlertsFeed() {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disappearingAlerts, setDisappearingAlerts] = useState([]);

  /** Fetch Alerts */
  const fetchAlerts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await apiClient.get('/feed/kapperUpdates');

      if (!Array.isArray(response?.data)) {
        throw new Error('Invalid response format');
      }

      setAlerts(response.data);
      setError(null);
    } catch (err) {
      console.error('[ERROR] Fetching alerts:', err);
      setError('Failed to load alerts. Please try again later.');
      setAlerts([]);
    } finally {
      setLoading(false);
    }
  }, []);

  /** Fetch alerts on mount */
  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  /** Generic function to update alerts */
  const updateAlerts = useCallback((alertId, updateFn) => {
    setAlerts((prev) =>
      prev.map((alertItem) => (alertItem.alert_id === alertId ? updateFn(alertItem) : alertItem))
    );
  }, []);

  /** Handle Delete Alert */
const handleDeleteAlert = useCallback(async (alertId) => {
  if (!alertId) {
    console.error('[ERROR] handleDeleteAlert called without alert_id!');
    return;
  }

  try {
    console.log(`[DEBUG] Deleting alert with ID: ${alertId}`);
    await apiClient.post('/api/alerts/delete', { alert_id: alertId });

    // Start the disappearing animation
    setAlerts((prev) =>
      prev.map((alertItem) =>
        alertItem.alert_id === alertId ? { ...alertItem, isDisappearing: true } : alertItem
      )
    );

    // Wait for animation before actually removing it
    setTimeout(() => {
      setAlerts((prev) => prev.filter((alertItem) => alertItem.alert_id !== alertId));
    }, 300); // Match this to CSS animation duration
  } catch (err) {
    if (err.response && err.response.status === 404) {
      console.warn(`[WARN] Alert ${alertId} not found on backend, likely already deleted.`);
    } else {
      console.error('Error deleting alert:', err.response?.data || err.message);
      alert('Failed to delete the alert. Please try again.');
    }
  }
}, []);

  /** Handle Pin Alert */
  const handlePinAlert = useCallback(async (alertId, isPinned) => {
    if (!alertId) {
      console.error('[ERROR] handlePinAlert called without alert_id!');
      window.alert('Error: Missing alert ID. Please refresh and try again.');
      return;
    }

    try {
      console.log(`[DEBUG] Sending pin request for alert_id: ${alertId}, isPinned: ${isPinned}`);

      const response = await apiClient.post('/alerts/pin', { alert_id: alertId, is_pinned: isPinned });

      console.log(`[DEBUG] Pin response:`, response.data);

      updateAlerts(alertId, (alertItem) => ({
        ...alertItem,
        is_pinned: response.data.is_pinned,
      }));
    } catch (err) {
      console.error('Error pinning alert:', err.response?.data || err.message);
      window.alert('Failed to update pin status. Please try again.');
    }
  }, [updateAlerts]);

  /** Render Alerts */
  const renderAlerts = useCallback(() => {
    if (!Array.isArray(alerts) || alerts.length === 0) return null;

    return alerts.map((alertItem) => (
      <KapperAlertCard
        key={alertItem.alert_id}
        alert={alertItem}
        onDelete={handleDeleteAlert}
        onPin={handlePinAlert}
        isDisappearing={disappearingAlerts.includes(alertItem.alert_id)}
      />
    ));
  }, [alerts, handleDeleteAlert, handlePinAlert, disappearingAlerts]);

  /** Render Content */
const renderContent = () => {
  if (loading) return <div className="alerts-loading">Loading updates...</div>;
  if (error) return <div className="alerts-error">{error}</div>;

  const visibleAlerts = alerts.filter((alert) => !alert.isDisappearing); // Filter out disappearing alerts

  if (visibleAlerts.length === 0) {
    return (
      <>
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">Active Updates</span>
          <hr className="divider-line" />
        </div>
        <div className="alerts-empty">
          <p>You have no updates posted.</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="feed-post-divider">
        <hr className="divider-line" />
        <span className="divider-text">Active Updates</span>
        <hr className="divider-line" />
      </div>
      {visibleAlerts.map((alertItem) => (
        <KapperAlertCard
          key={alertItem.alert_id}
          alert={alertItem}
          onDelete={handleDeleteAlert}
          onPin={handlePinAlert}
          isDisappearing={alertItem.isDisappearing} // Pass animation state
        />
      ))}
    </>
  );
};


  return <div className="alerts-container">{renderContent()}</div>;
}

export default KapperAlertsFeed;
