import React, { useRef, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import ReactionBar from '../ReactionBar/ReactionBar';
import './KapperPostCard.css';

const STATIC_URL = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

const KapperPostCard = ({ post, onMarkAsSettled, isDisappearing }) => {
    const tapTimeoutRef = useRef(null);
    const [isSettling, setIsSettling] = useState(false);

    const handleDoubleTap = async () => {
        if (isSettling || post.is_settled == 1) return; // Ensure checking as number or string
        setIsSettling(true);
        console.log(`[DEBUG] Double tap detected - Settling post ${post.id}`);

        try {
            await onMarkAsSettled(post.id);
            console.log(`[DEBUG] Successfully marked post ${post.id} as settled`);
        } catch (error) {
            console.error(`[ERROR] Failed to mark post ${post.id} as settled:`, error.message);
        } finally {
            setIsSettling(false);
        }
    };

    const handleTap = () => {
        if (tapTimeoutRef.current) {
            clearTimeout(tapTimeoutRef.current);
            tapTimeoutRef.current = null;
            handleDoubleTap();
        } else {
            tapTimeoutRef.current = setTimeout(() => {
                tapTimeoutRef.current = null;
            }, 300); 
        }
    };

    return (
        <div
            className={`feed-post-card ${post.is_settled == 0 ? 'active-border' : ''} ${
                isDisappearing ? 'disappearing' : ''
            }`}
            onClick={handleTap}
        >
            <div className="feed-post-header">
                <div className="feed-post-top">
                <span className="feed-post-date feed-post-timestamp-large">
                    {formatDistanceToNow(new Date(post.created_at), { addSuffix: true })}
                </span>
                    <span className="kapper-post-odds">
        {post.odds === 100 ? 'Even' : post.odds > 0 ? `+${post.odds}` : post.odds}
    </span>
</div>
                <div className="feed-post-units-league-container">
                    <span className="feed-post-league">{post.league_with_emoji}</span>
                    <span className="feed-post-units">
                        {post.units} {post.units === 1 ? 'Unit' : 'Units'}
                    </span>
                </div>
            </div>

            <div className="feed-post-content">
                <h5 className="feed-post-title">{post.title}</h5>

                {Array.isArray(post.bet_legs) && post.bet_legs.length > 0 && (
                    <ul className="feed-bet-legs">
                        {post.bet_legs.map((leg, index) => (
                            <li key={index}>{leg.trim()}</li> 
                        ))}
                    </ul>
                )}

                {post.image && (
                    <img
                        src={`${STATIC_URL}/${post.image}`}
                        alt="Post Image"
                        className="feed-post-image"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/default-image.png';
                        }}
                    />
                )}
            </div>


<ReactionBar
    totalPlays={Number(post.total_plays)}
    totalPasses={Number(post.total_passes)}
    onReact={(isPlay) => {
        console.log(`Reacted ${isPlay ? 'Play' : 'Pass'} on KapperPostCard`);
    }}
    isSettled={post.is_settled === 1} // ✅ Ensure this correctly reflects post status
/>
        </div>
    );
};

export default KapperPostCard;
