import React, { useEffect, useState } from 'react';
import apiClient from '../../services/apiClient';
import SubscriberFeed from '../../feeds/Subscribers/SubscriberFeed/SubscriberFeed';
import KapperFeed from '../../feeds/Kappers/KapperFeed/KapperFeed';
import './HomePage.css';

function HomePage() {
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await apiClient.get('users/me'); // ✅ Fetch user role
        console.log('[DEBUG] User Role:', response.data.role);
        setUserRole(response.data.role);
      } catch (error) {
        console.error('[ERROR] Failed to fetch user role:', error);
        setUserRole(null); // Default to null in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchUserRole();
  }, []);

  if (loading) {
    return <div className="home-page-container">Loading...</div>;
  }

  return (
    <div className="home-page-container">
      {userRole === 'pro' ? <KapperFeed /> : <SubscriberFeed />}
    </div>
  );
}

export default HomePage;
