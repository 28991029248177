import React, { useState, useEffect } from 'react';
import Profile from '../../../components/accounts/Kappers/Profile/Profile';
import DiscordConnect from '../../../components/accounts/Kappers/DiscordServer/DiscordConnect';
import AverageOdds from '../../../components/accounts/Kappers/AverageOdds/AverageOdds';
import KapperAlertsFeed from '../../../feeds/AlertsFeed/AlertFeed';
import AboutMe from '../../../components/accounts/Kappers/AboutMe/AboutMe';
import './ProfilePage.css';
import apiClient from '../../../services/apiClient'; // Adjust import based on your API client setup

function ProfilePage() {
  const [userRole, setUserRole] = useState(null); // Track the user's role
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track any errors

  // Fetch user details (including role) on component mount
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await apiClient.get('/users/me'); // Replace with your API endpoint
        setUserRole(response.data.role); // Assuming the role is returned as `role`
      } catch (err) {
        console.error('Error fetching user role:', err.message);
        setError('Failed to load user role.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserRole();
  }, []);

  // Handle loading or error states
  if (loading) {
    return <div className="profile-page-loading">Loading...</div>;
  }

  if (error) {
    return <div className="profile-page-error">{error}</div>;
  }

  return (
    <div className="profile-page">
      <Profile />
            <div className="feed-post-divider">
        <hr className="divider-line" />
        <span className="divider-text">Community Access</span>
        <hr className="divider-line" />
      </div>
      {userRole === 'pro' && <DiscordConnect />} 
      <AboutMe />
      <div className="feed-post-divider">
        <hr className="divider-line" />
        <span className="divider-text">Kapper Stats</span>
        <hr className="divider-line" />
      </div>
      {/* <KapperUnits /> */}
      <AverageOdds />
      <KapperAlertsFeed />
    </div>
  );
}

export default ProfilePage;
