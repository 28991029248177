import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../../services/apiClient';
import FeedPostCard from '../../../components/cards/FeedPostCard/FeedPostCard';
import './RecentWinFeed.css';

function RecentWinFeed() {
  const { username } = useParams();
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bankroll, setBankroll] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const observer = useRef();

  /** 🔹 Fetch Bankroll */
  useEffect(() => {
    const fetchBankroll = async () => {
      try {
        const { data } = await apiClient.get('/stats/users/bankroll');
        setBankroll(data.bankroll || null);
      } catch (err) {
        console.error('Error fetching bankroll:', err.message);
        setBankroll(null);
      }
    };
    fetchBankroll();
  }, []);

  /** 🔹 Fetch Posts */
  const fetchFeed = async (pageNum) => {
    if (loading || !hasMore) return; // Prevent duplicate requests
    setLoading(true);

    try {
      const { data } = await apiClient.get(`/kappers/${username}/recentWinFeed`, {
        params: { limit: 5, offset: pageNum * 5 },
      });

      const enrichedPosts = data.map((post) => ({
        ...post,
        image: post.image || null,
        total_plays: Number(post.total_plays),
        bet_legs: post.bet_legs ? post.bet_legs.split(';') : [],
      }));

      setFeed((prevFeed) => [...prevFeed, ...enrichedPosts]);
      setHasMore(enrichedPosts.length === 5); // If less than 5, no more data
    } catch (err) {
      console.error('Error fetching posts:', err.message);
      setError('Failed to load recent wins. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  /** 🔹 Load More When Last Post is in View */
  const lastPostRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1.0 }
      );

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  /** 🔹 Trigger Fetch When Page Updates */
  useEffect(() => {
    fetchFeed(page);
  }, [page]);

  /** 🔹 Group Posts By Date */
  const groupedFeed = useMemo(() => {
    return Object.entries(
      feed.reduce((acc, item) => {
        const itemDate = new Date(item.created_at);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        let groupLabel;
        if (itemDate.toDateString() === today.toDateString()) {
          groupLabel = 'Today';
        } else if (itemDate.toDateString() === yesterday.toDateString()) {
          groupLabel = 'Yesterday';
        } else {
          groupLabel = itemDate.toLocaleDateString();
        }

        if (!acc[groupLabel]) acc[groupLabel] = [];
        acc[groupLabel].push(item);
        return acc;
      }, {})
    );
  }, [feed]);

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (feed.length === 0 && !loading) {
    return (
      <div className="feed-container">
        <div className="feed-empty">
          <div className="feed-empty-text">No settled slips yet.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="feed-container">
      {groupedFeed.map(([label, items]) => (
        <div key={label} className="feed-post-group">
          <div className="feed-post-divider">
            <hr className="divider-line" />
            <span className="divider-text">{label}</span>
            <hr className="divider-line" />
          </div>
          {items.map((item, index) => (
            <FeedPostCard
              key={item.post_id}
              post={item}
              bankroll={bankroll}
              ref={index === items.length - 1 ? lastPostRef : null}
            />
          ))}
        </div>
      ))}

      {loading && <div className="feed-loading">Loading more...</div>}
    </div>
  );
}

export default RecentWinFeed;
