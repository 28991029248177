import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import LoginRegisterModal from '../login/LoginRegisterModal/LoginRegisterModal';
import apiClient from '../../services/apiClient';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faMagnifyingGlass,
  faGear,
  faBars,
  faTimes,
  faRightFromBracket,
  faUser,
  faChartLine,
  faCheckToSlot,
  faQuestion,
  faPlus,
  faArrowsRotate
} from '@fortawesome/free-solid-svg-icons';
import {
faMoneyBill1,
    } from '@fortawesome/free-regular-svg-icons';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!localStorage.getItem('accessToken');
  const role = localStorage.getItem('role');
  const isLandingPage = location.pathname === '/';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('login');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

useEffect(() => {
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
    console.log("Scroll position:", window.scrollY);
    console.log("isScrolled:", window.scrollY > 50);
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);


  useEffect(() => {
    const fetchProfilePicture = async () => {
        try {
          const response = await apiClient.get('/users/profile');
          const fullPath =
            response.data.profile_picture &&
            `${
              process.env.NODE_ENV === 'production'
                ? process.env.REACT_APP_STATIC_BASE_URL
                : 'http://localhost:5000'
            }/${response.data.profile_picture}`;
          setProfilePicture(fullPath || '/profile.png');
        } catch (error) {
          console.error('Error fetching profile picture:', error);
          setProfilePicture('/profile.png');
        }
    };

    fetchProfilePicture();
  }, [isLoggedIn, role]);

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    navigate('/');
    setIsSidebarOpen(false);
  };

  const openModal = (mode) => {
    setModalMode(mode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setIsSidebarOpen(true),
    onSwipedRight: () => setIsSidebarOpen(false),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  const [bankroll, setBankroll] = useState(null); // Add bankroll state

useEffect(() => {
  const fetchBankroll = async () => {
    try {
      const response = await apiClient.get('/stats/users/bankroll');
      if (response.data.success) {
        setBankroll(response.data.bankroll);
      } else {
        setBankroll(null);
      }
    } catch (error) {
      console.error('Error fetching bankroll:', error);
      setBankroll(null);
    }
  };

  fetchBankroll();
}, [isLoggedIn]); // Fetch bankroll when the user logs in


return (
  <>
    {/* Top Navbar */}


<nav className={`navbar ${isScrolled ? 'navbar-scrolled' : ''} ${!isLoggedIn && isLandingPage && !isScrolled ? 'transparent-navbar' : ''}`}>

  <div className="navbar-left">
    <NavLink to="/" className="navbar-logo">
      <img
        src={!isLoggedIn && isLandingPage && !isScrolled ? 'landingpagelogo.png' : 'k.png'}
        alt="Kapper Logo"
        className="navbar-logo-img"
      />
    </NavLink>
  </div>


  <ul className="nav-links">
    {isLoggedIn ? (
      <>
        {/* Icons to hide on mobile */}
        <li className="nav-icon-item">
          <NavLink to="/home" className={({ isActive }) => (isActive ? 'active' : '')}>
 Home
          </NavLink>
        </li>
        <li className="nav-icon-item">
          <NavLink to="/discover" className={({ isActive }) => (isActive ? 'active' : '')}>
 Discover
          </NavLink>
            </li>
        <li className="nav-icon-item">
          <NavLink to="/post" className={({ isActive }) => (isActive ? 'active' : '')}>
 Post
          </NavLink>
            </li>            
        <li className="nav-icon-item">
          <NavLink to="/kapperstats" className={({ isActive }) => (isActive ? 'active' : '')}>
 MySlips
          </NavLink>
        </li>
        <li className="nav-icon-item">
          <NavLink to="/profile" className={({ isActive }) => (isActive ? 'active' : '')}>
Profile
          </NavLink>
            </li>
        {/* Sidebar Toggle */}
        <li
          className={`nav-item sidebar-toggle ${isSidebarOpen ? 'active' : ''}`}
          onClick={toggleSidebar}
        >
          <li type="button" className="sidebar-toggle-icon">
            <FontAwesomeIcon
              icon={isSidebarOpen ? faTimes : faBars}
              className="nav-icon"
            />
          </li>
        </li>
      </>
    ) : (
      <>
        {/* Login and Signup buttons for logged-out users */}
              <li>
                <button
            to="#"
            className="nav-login-button"
            onClick={() => openModal('login')}
          >
                  Login
                  </button>
        </li>
        <li>
          <button
            className="nav-signup-button"
            onClick={() => openModal('register')}
          >
            Sign Up
          </button>
        </li>
      </>
    )}
  </ul>
</nav>


    {/* Sidebar */}
    <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
      <ul className="sidebar-menu">
        <li>
        </li>
        <li>
          <Link to="/help" onClick={toggleSidebar} className="sidebar-item">
            <span className="sidebar-text">Help</span>
          </Link>
        </li>
      </ul>
      <div className="sidebar-logout">
        <Link className="logout-link" onClick={handleLogout}>
          <FontAwesomeIcon icon={faRightFromBracket} className="logout-icon" />
          Logout
        </Link>
      </div>
    </div>

    {/* Sidebar Overlay */}
    {isSidebarOpen && (
      <div
        className="sidebar-overlay"
        onClick={() => setIsSidebarOpen(false)}
      ></div>
    )}

    {/* Bottom Navbar */}
    {isLoggedIn && (
      <div className="bottom-navbar">
        <Link to="/home">
          <FontAwesomeIcon
            icon={faHouse}
            className={`nav-icon ${
              location.pathname === '/home' ? 'active' : ''
            }`}
          />
        </Link>
        <Link to="/discover">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className={`nav-icon ${
              location.pathname === '/discover' ? 'active' : ''
            }`}
          />
        </Link>
{role === 'pro' && (
  <Link to="/post">
    <FontAwesomeIcon
      icon={faPlus}
      className={`post-icon ${location.pathname === '/post' ? 'active' : ''}`}
    />
  </Link>
)}

        <Link to="/kapperstats">
          <FontAwesomeIcon
                icon={role === 'pro' ? faCheckToSlot : faArrowsRotate}
            className={`nav-icon ${
              location.pathname === '/kapperstats' ? 'active' : ''
            }`}
          />
        </Link>
<Link to="/profile">
  <FontAwesomeIcon
    icon={role === 'pro' ? faUser : faUser}
    className={`nav-icon ${
      location.pathname === '/profile' ? 'active' : ''
    }`}
  />
</Link>

      </div>
    )}

    {/* Login/Register Modal */}
    {isModalOpen && (
      <LoginRegisterModal mode={modalMode} closeModal={closeModal} />
    )}
  </>
);



}

export default Navbar;
