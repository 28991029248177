import React, { memo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../../services/apiClient';
import { formatDistanceToNow } from 'date-fns';
import ReactionBar from '../ReactionBar/ReactionBar';
import './FeedPostCard.css';

const STATIC_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_STATIC_BASE_URL
  : 'http://localhost:5000';

const FeedPostCard = memo(({ post, updateFeed = () => {}, bankroll, disablePlay = false }) => {
  const profilePicture = React.useMemo(() => {
    return post.profile_picture ? `${STATIC_URL}/${post.profile_picture}` : '/profile.png';
  }, [post.profile_picture]);

  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(`/${post.kapper_display_name}`);
  };

  const tapTimeoutRef = useRef(null);

  const handleTap = () => {
    if (tapTimeoutRef.current) {
      clearTimeout(tapTimeoutRef.current);
      tapTimeoutRef.current = null;
      handleDoubleTapReaction();
    } else {
      tapTimeoutRef.current = setTimeout(() => {
        tapTimeoutRef.current = null;
      }, 300);
    }
  };

  const handleDoubleTapReaction = async () => {
    if (disablePlay) return;

    const isCurrentlyReacted = post.user_reaction === 1;
    const updatedTotalPlays = isCurrentlyReacted
      ? Math.max(0, post.total_plays - 1)
      : post.total_plays + 1;

    const newPlayState = isCurrentlyReacted ? 0 : 1;

    updateFeed(post.post_id, newPlayState, updatedTotalPlays);

    try {
      await apiClient.post('/reactions/react', { post_id: post.post_id, is_play: newPlayState });
    } catch (error) {
      console.error('Error handling reaction:', error.response?.data || error.message);

      const revertedTotalPlays = isCurrentlyReacted
        ? post.total_plays
        : Math.max(0, post.total_plays - 1);
      updateFeed(post.post_id, post.user_reaction, revertedTotalPlays);
    }
  };

  const displayValue =
    bankroll !== null && post.conversion_rate > 0
      ? `$${(bankroll * post.conversion_rate * (parseFloat(post.units) || 0)).toFixed(2)}`
      : `${parseFloat(post.units) || 0} ${parseFloat(post.units) === 1 ? 'Unit' : 'Units'}`;

  const betLegs = post.bet_legs || [];

  // ✅ Apply border only if post is played
  const postBorderClass = post.user_reaction === 1 ? 'play-border' : '';

  return (
    <div className={`feed-post-card`} onClick={handleTap}>
      <div className="feed-post-header">
                        <div className="feed-post-top">
        <div className="feed-post-header-left">
          <button
            className="profile-button"
            onClick={handleProfileClick}
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            <img
              src={profilePicture}
              alt={`${post.kapper_display_name}'s profile`}
              className="feed-profile-picture"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/profile.png';
              }}
            />
            </button>
            
          <div className="feed-post-header-right">
            <h5 className="feed-post-kapper">{post.kapper_display_name}</h5>
            <span className="feed-post-date">
              {formatDistanceToNow(new Date(post.created_at), { addSuffix: true })}
            </span>
          </div>
          </div>

      {/* ✅ ADDING ODDS BELOW CONTENT */}
      <div className="feed-post-odds">
        {post.odds === 100 ? 'Even' : post.odds > 0 ? `+${post.odds}` : post.odds}
      </div>

          </div>
        <div className="feed-post-units-league-container">
          <span className="feed-post-league">{post.league_with_emoji}</span>
          <span className="feed-post-units">{displayValue}</span>
        </div>
      </div>

      <div className="feed-post-content">
        <h5 className="feed-post-title">{post.title}</h5>
        {betLegs.length > 0 && (
          <ul className="feed-bet-legs">
            {betLegs.map((leg, index) => (
              <li key={index}>{leg}</li>
            ))}
          </ul>
        )}
        {post.image && (
          <img
            src={`${STATIC_URL}/${post.image}`}
            alt="Post Image"
            className={`feed-post-image`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/default-image.png';
            }}
          />
        )}
      </div>

      {post.analysis && <p className="feed-post-analysis">*{post.analysis}</p>}

      <ReactionBar
        totalPlays={Number(post.total_plays)}
        onReact={handleDoubleTapReaction}
        userReacted={post.user_reaction === 1}
        isSettled={post.is_settled === 1}
      />
    </div>
  );
});

export default FeedPostCard;
