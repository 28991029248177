import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../../services/apiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Kapper.css';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

function Kapper({ openLoginModal }) {
  const { username } = useParams();
  const [kapperDetails, setKapperDetails] = useState(null);
  const [isFollowed, setIsFollowed] = useState(false); // Track following status
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [currentUsername, setCurrentUsername] = useState(null);


useEffect(() => {
  // Fetch Kapper details
apiClient
  .get(`/kappers/${username}`)
  .then((response) => {
    setKapperDetails({
      ...response.data,
      role: response.data.role, // Include role in kapperDetails
      follower_count: response.data.follower_count || 0,
    });

    // Fetch the current user's username
    const token = localStorage.getItem('accessToken');
    if (token) {
      apiClient
        .get('/users/profile')
        .then((profileResponse) => {
          setCurrentUsername(profileResponse.data.username);
        })
        .catch((error) => {
          console.error('Error fetching current user details:', error.response?.data || error.message);
        });
    }
  })
  .catch((error) => {
    console.error('Error fetching kapper details:', error.response?.data || error.message);
  });

  // Check subscription and following status
  const token = localStorage.getItem('accessToken');
  if (token) {
    // Check following status
    apiClient
      .get('/following/followers')
      .then((response) => {
        const followers = response.data?.data || []; // Safely access the array
        const isUserFollowing = followers.some(
          (follower) => follower.kapper_username === username
        );
        setIsFollowed(isUserFollowing);
      })
      .catch((error) => {
        console.error('Error fetching followers:', error.response?.data || error.message);
      });

    // Check subscription status
    apiClient
      .get('/subscription/subscriptions')
      .then((response) => {
        const subscriptions = response.data || [];
        const isUserSubscribed = subscriptions.some(
          (subscription) => subscription.username === username
        );
        setIsSubscribed(isUserSubscribed);
      })
      .catch((error) => {
        console.error('Error fetching subscriptions:', error.response?.data || error.message);
      });
  }
}, [username]);

const handleFollow = async () => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    openLoginModal('login'); // Open the login modal
    return;
  }

  try {
    await apiClient.post('/following/follow', { username });
    
    // Update state immediately
    setIsFollowed(true);
    setKapperDetails((prevDetails) => ({
      ...prevDetails,
      follower_count: (prevDetails.follower_count || 0) + 1,
    }));
  } catch (error) {
    console.error('Error following kapper:', error.response?.data || error.message);
  }
};

const handleUnfollow = async () => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    openLoginModal('login');
    return;
  }

  try {
    await apiClient.post('/following/unfollow', { username });

    // Update state immediately
    setIsFollowed(false);
    setKapperDetails((prevDetails) => ({
      ...prevDetails,
      follower_count: Math.max((prevDetails.follower_count || 0) - 1, 0),
    }));
  } catch (error) {
    console.error('Error unfollowing kapper:', error.response?.data || error.message);
  }
};

  const handleSubscribe = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      // If user is not logged in, open the login modal
      openLoginModal('login'); // Open the login modal
      return;
    }

    try {
      const response = await apiClient.post('/subscription/subscribe', { username });
      setIsSubscribed(true);
    } catch (error) {
      console.error('Error subscribing:', error.response?.data || error.message);
    }
  };

  if (!kapperDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile">
      <div className="card profile-container">
        <div className="banner">
          <img
            src={
              kapperDetails.banner_picture
                ? `${STATIC_URL}/${kapperDetails.banner_picture}`
                : '/banner.png'
            }
            alt="Banner"
            className="banner-image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/banner.png';
            }}
          />
        </div>
        <div className="profile-picture-container">
          <img
            src={
              kapperDetails.profile_picture
                ? `${STATIC_URL}/${kapperDetails.profile_picture}`
                : '/profile.png'
            }
            alt="Profile"
            className="profile-picture"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/profile.png';
            }}
          />
        </div>
        <div className="stats-row">
  {kapperDetails.role === 'pro' ? (
    <p className="subscribers">{kapperDetails.subscriber_count || 0} Subscribers</p>
  ) : (
    <p className="followers-following">
    {kapperDetails.follower_count || 0} Followers
    </p>
  )}
</div>
<h1 className="username">@{kapperDetails.username}</h1>
<p className="display-name">{kapperDetails.display_name || kapperDetails.username}   {kapperDetails.role === 'pro' && (
    <FontAwesomeIcon icon={faCheckCircle} className="badge-icon" />
  )}</p>

<p className="bio">{kapperDetails.bio}</p>

        <div className="leagues-section">
          {kapperDetails.leagues?.length > 0 ? (
            <div className="leagues-container">
              {kapperDetails.leagues.map((league, index) => (
                <button key={index} className="league-button selected" disabled>
                  {league}
                </button>
              ))}
            </div>
          ) : (
            <p></p>
          )}
        </div>
  <div className="blank-space"></div> 
      </div>
    </div>
  );
}

export default Kapper;
