import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../../services/apiClient';
import './KapperAlertCard.css';

const STATIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STATIC_BASE_URL
    : 'http://localhost:5000';

const KapperAlertCard = ({ alert, onDelete, onPin }) => {
  const [isProcessingPin, setIsProcessingPin] = useState(false);
  const [localPinnedState, setLocalPinnedState] = useState(alert.is_pinned);
  const [isDisappearing, setIsDisappearing] = useState(false);

  const handleDelete = async () => {
    try {
      setIsDisappearing(true);

      setTimeout(async () => {
        await apiClient.post('/alerts/delete', { alert_id: alert.alert_id });
        console.log('Alert deleted successfully.');
        onDelete(alert.alert_id);
      }, 300); // Match CSS animation duration
    } catch (err) {
      console.error('Error deleting alert:', err.response?.data || err.message);
      window.alert('Failed to delete the alert. Please try again.');
    }
  };

  const handlePin = async () => {
    if (isProcessingPin) return;
    setIsProcessingPin(true);
    setLocalPinnedState((prevState) => !prevState);

    try {
      const response = await apiClient.post('/alerts/pin', { alert_id: alert.alert_id });
      console.log(`Alert pin status updated successfully to ${response.data.is_pinned}`);
      onPin(alert.alert_id, response.data.is_pinned);
    } catch (err) {
      console.error('Error updating pin status:', err.response?.data || err.message);
      window.alert('Failed to update pin status. Please try again.');
    } finally {
      setIsProcessingPin(false);
    }
  };

  return (
    <div
      className={`feed-post-card ${isDisappearing ? 'disappearing' : ''}`}
    >
      <div className="alert-actions">
        <button
          className="pin-alert-button"
          onClick={() => handlePin()}
          style={{ backgroundColor: localPinnedState ? '#5865F2' : '#555' }}
        >
          <FontAwesomeIcon icon={faThumbtack} />
        </button>
        <button className="delete-alert-button" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      <div className="feed-post-header">
        <span className="feed-post-date feed-post-timestamp-large">
          {formatDistanceToNow(new Date(alert.created_at), { addSuffix: true })}
        </span>
      </div>
      <div className="feed-alert-content">
        <h5 className="feed-post-title">{alert.title}</h5>
        <p className="alert-body">{alert.body}</p>
        {alert.image && (
          <img
            src={`${STATIC_URL}/${alert.image}`}
            alt="Alert"
            className="feed-alert-image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/default-image.png';
            }}
          />
        )}
      </div>
    </div>
  );
};

export default KapperAlertCard;
