import React, { useEffect, useState, useMemo } from 'react';
import apiClient from '../../../services/apiClient';
import KapperPostCard from '../../../components/cards/KapperPostCard/KapperPostCard';
import './KapperSettledFeed.css';

function KapperSettledFeed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

const fetchKapperSettledFeed = async (limit, offset) => {
  setLoading(true);
  try {
    const response = await apiClient.get('/feed/kapperSettledFeed', {
      params: { limit, offset },
    });

    setPosts((prevPosts) => [
      ...prevPosts,
      ...response.data.map((post) => ({
        ...post,
        bet_legs: typeof post.bet_legs === 'string' 
          ? post.bet_legs.split(';') // ✅ Convert string to array
          : Array.isArray(post.bet_legs) 
          ? post.bet_legs // ✅ Keep as array if already an array
          : [], // ✅ Default to empty array if `null` or `undefined`
      })),
    ]);
    setHasMore(response.data.length === limit);
  } catch (err) {
    console.error('Error fetching Kapper Settled Feed:', err.message);
    setError('Failed to load feed. Please try again later.');
  } finally {
    setLoading(false);
  }
};


  useEffect(() => {
    fetchKapperSettledFeed(5, 0);
  }, []);

  useEffect(() => {
    if (page > 0) {
      fetchKapperSettledFeed(5, page * 5);
    }
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (!loading && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]);

  // Group posts by date
  const groupedPosts = useMemo(() => {
    return Object.entries(
      posts.reduce((acc, post) => {
        const postDate = new Date(post.created_at);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        let groupLabel =
          postDate.toDateString() === today.toDateString()
            ? 'Today'
            : postDate.toDateString() === yesterday.toDateString()
            ? 'Yesterday'
            : postDate.toLocaleDateString();

        if (!acc[groupLabel]) acc[groupLabel] = [];
        acc[groupLabel].push(post);
        return acc;
      }, {})
    );
  }, [posts]);

  // Render grouped posts with dividers (ONLY `KapperPostCard`)
  const renderGroupedPosts = () =>
    groupedPosts.map(([label, items]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {items.map((post) => (
          <KapperPostCard key={post.post_id} post={post} />
        ))}
      </div>
    ));

  if (loading && posts.length === 0) {
    return <div className="feed-loading">Loading...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (posts.length === 0) {
    return (
      <div className="feed-container">
        <div className="feed-empty">No settled slips yet.</div>
      </div>
    );
  }

  return (
    <div className="feed-container">
      {renderGroupedPosts()}
      {loading && hasMore && <div className="feed-loading">Loading more...</div>}
    </div>
  );
}

export default KapperSettledFeed;
