import React, { useEffect, useState, useCallback, useMemo } from 'react';
import apiClient from '../../../services/apiClient';
import FeedPostCard from '../../../components/cards/FeedPostCard/FeedPostCard';
import AlertCard from '../../../components/cards/AlertCard/AlertCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBed} from '@fortawesome/free-solid-svg-icons';
import './SubscriberFeed.css';

function SubscriberFeed() {
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bankroll, setBankroll] = useState(null);

  useEffect(() => {
    const fetchBankroll = async () => {
      try {
        const { data } = await apiClient.get('/stats/users/bankroll');
        setBankroll(data.bankroll || null);
      } catch (err) {
        console.error('Error fetching bankroll:', err.message);
        setBankroll(null);
      }
    };

const fetchFeed = async () => {
  try {
    const { data: feedData } = await apiClient.get('/feed');
    const { posts = [], alerts = [] } = feedData;

    const combinedFeed = [...alerts, ...posts].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    setFeed(
      combinedFeed.map((item) => ({
        ...item,
        image: item.image || null,
        total_plays: Number(item.total_plays),
        user_reaction: item.user_reaction !== null ? Number(item.user_reaction) : 0, // Properly initialize user_reaction
      }))
    );
  } catch (err) {
    console.error('Error fetching feed:', err.message);
    setError('Failed to load feed. Please try again later.');
  } finally {
    setLoading(false);
  }
};


    fetchBankroll();
    fetchFeed();
  }, []);

const updateFeed = useCallback((postId, userReaction, updatedTotalPlays = null) => {
  setFeed((prevFeed) =>
    prevFeed.map((item) => {
      if (item.post_id === postId) {
        const backendReaction = item.user_reaction; // Reflect backend state
        const newTotalPlays =
          updatedTotalPlays !== null
            ? updatedTotalPlays
            : userReaction === 1 && backendReaction !== 1
            ? item.total_plays + 1 // Increment if reacting and no prior reaction
            : userReaction === 0 && backendReaction === 1
            ? Math.max(0, item.total_plays - 1) // Decrement if unreacting
            : item.total_plays; // No change if states match

        return {
          ...item,
          user_reaction: userReaction, // Toggle reaction state
          total_plays: newTotalPlays, // Update total plays
        };
      }
      return item; // Leave other posts unchanged
    })
  );
}, []);


  const groupedFeed = useMemo(() => {
    return Object.entries(
      feed.reduce((acc, item) => {
        const itemDate = new Date(item.created_at);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        let groupLabel;

        if (itemDate.toDateString() === today.toDateString()) {
          groupLabel = 'Today';
        } else if (itemDate.toDateString() === yesterday.toDateString()) {
          groupLabel = 'Yesterday';
        } else {
          groupLabel = itemDate.toLocaleDateString();
        }

        if (!acc[groupLabel]) acc[groupLabel] = [];
        acc[groupLabel].push(item);
        return acc;
      }, {})
    );
  }, [feed]);

  const handleDismissAlert = (alertId) => {
    setFeed((prevFeed) => prevFeed.filter((item) => item.alert_id !== alertId));
  };

  const renderPostGroups = () => {
    return groupedFeed.map(([label, items]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {items.map((item) => {
          if (item.post_id) {
            return (
              <FeedPostCard
                key={item.post_id}
                post={item}
                updateFeed={updateFeed}
                bankroll={bankroll}
              />
            );
          }
          if (item.alert_id) {
            return (
              <AlertCard
                key={item.alert_id}
                alert={item}
                onDismiss={handleDismissAlert}
              />
            );
          }
          return null;
        })}
      </div>
    ));
  };

  if (loading) {
    return <div className="feed-loading">Loading...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (feed.length === 0) {
    return <div className="feed-container">
      
      <div className="feed-empty"><div className="feed-empty-text">No active slips.</div>
      </div></div>
  }

  return <div className="feed-container">{renderPostGroups()}</div>;
}

export default SubscriberFeed;
