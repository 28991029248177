import React from 'react';
import Kapper from '../../components/username/Kapper/Kapper'; // Adjust the path based on your folder structure
import KapperStats from '../../components/username/KapperStats/KapperStats';
import About from '../../components/username/About/About';
import AverageOddsByUsername from '../../components/username/AverageOddsByUsername/AverageOddsByUsername';
import RecentWinFeed from '../../components/username/RecentWinFeed/RecentWinFeed';
import KapperDiscordCard from '../../components/username/KapperDiscordCard/KapperDiscordCard';
import './KapperPage.css';

function KapperPage({ openLoginModal }) {
  return (
    <div className="kapper-page-container">
      {/* Pass openLoginModal to Kapper */}
      <Kapper openLoginModal={openLoginModal} />
            <div className="feed-post-divider">
        <hr className="divider-line" />
        <span className="divider-text">Community Access</span>
        <hr className="divider-line" />
      </div>
      <About />
      {/* <KapperDiscordCard /> */}
      {/* Divider for Stats */}
      <div className="feed-post-divider">
        <hr className="divider-line" />
        <span className="divider-text">My Kapper Stats</span>
        <hr className="divider-line" />
      </div>
      <KapperStats />
      <AverageOddsByUsername />
      <RecentWinFeed />
    </div>
  );
}

export default KapperPage;
