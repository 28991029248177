import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../../services/apiClient';
import './KapperStats.css';

const KapperStats = () => {
  const { username } = useParams();
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // State to track which view is active (default: Total)
  const [postsView, setPostsView] = useState('total');
  const [unitsView, setUnitsView] = useState('total');

  useEffect(() => {
    const fetchKapperStats = async () => {
      if (!username) {
        console.error('[ERROR] Username is missing in URL params');
        setError('Username is required.');
        setIsLoading(false);
        return;
      }

      try {
        console.log(`[DEBUG] Fetching kapper stats for username: ${username}`);
        const response = await apiClient.get(`/stats/kappers/stats/${username}`);
        console.log('[DEBUG] Response:', response.data);

        const { success, stats } = response.data;
        if (!success) {
          throw new Error('Failed to fetch stats');
        }

        setStats(stats);
      } catch (err) {
        console.error('[ERROR] Error fetching kapper stats:', err.message || err);
        setError('Failed to load kapper stats.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchKapperStats();
  }, [username]);

  const getDisplayValue = (type, view) => {
    if (!stats) return 'N/A';
    switch (view) {
      case '1M':
        return stats[`${type}PerMonth`] || 'N/A';
      case '1D':
        return stats[`${type}PerDay`] || 'N/A';
      default:
        return stats[`total${type.charAt(0).toUpperCase() + type.slice(1)}`] || 'N/A';
    }
  };

  return (
    <div className="card kapper-stats-card">
      <div className="card-margin">
        <h2 className="stats-header">Subscribers get access to:</h2>

        {isLoading ? (
          <p className="loading-text">Loading Kapper Stats...</p>
        ) : error ? (
          <p className="error-text">{error}</p>
        ) : stats ? (
          <div className="stats-list">
            {/* Posts */}
            <StatItem
              label="Posts"
              value={getDisplayValue('posts', postsView)}
              onChangeView={setPostsView}
              currentView={postsView}
            />

            {/* Units */}
            <StatItem
              label="Units"
              value={getDisplayValue('units', unitsView)}
              onChangeView={setUnitsView}
              currentView={unitsView}
            />
          </div>
        ) : (
          <p className="no-data-text">No stats available</p>
        )}
      </div>
    </div>
  );
};

const StatItem = ({ label, value, onChangeView, currentView }) => (
  <div className="stat-item">
    <span className="stat-label">{label}:</span>
    <span className="stat-value">{value}</span>
    <div className="toggle-buttons">
      <button
        className={`toggle-btn ${currentView === 'total' ? 'active' : ''}`}
        onClick={() => onChangeView('total')}
      >
        Total
      </button>
      <button
        className={`toggle-btn ${currentView === '1M' ? 'active' : ''}`}
        onClick={() => onChangeView('1M')}
      >
        1M
      </button>
      <button
        className={`toggle-btn ${currentView === '1D' ? 'active' : ''}`}
        onClick={() => onChangeView('1D')}
      >
        1D
      </button>
    </div>
  </div>
);

export default KapperStats;
