import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import apiClient from '../../../../services/apiClient';
import './discordServer.css';

const DiscordConnect = () => {
  const [serverName, setServerName] = useState('');
  const [guildId, setGuildId] = useState(''); // Store guild_id
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false); // Track connection status

  const fetchConnectionStatus = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get('/discord/status');
      const { connected, guild_name, guild_id } = response.data;

      console.log('[DEBUG] Fetched guild_id:', guild_id); // Debug log

      setConnected(connected);
      if (connected) {
        setServerName(guild_name);
        setGuildId(guild_id.toString()); // Ensure guild_id is treated as a string
      } else {
        setServerName('');
        setGuildId('');
      }
    } catch (error) {
      console.error('[ERROR] Failed to fetch Discord status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleConnect = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get('/discord/authorize');
      const oauthUrl = response.data.redirectUrl;

      if (!oauthUrl) {
        throw new Error('No redirectUrl received from backend');
      }

      window.location.href = oauthUrl; // Redirect to Discord OAuth
    } catch (error) {
      console.error('[ERROR] Failed to start Discord OAuth:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = async () => {
    try {
      const confirmed = window.confirm('Are you sure you want to remove this server connection?');
      if (!confirmed) return;

      console.log('[DEBUG] guild_id being sent to backend:', guildId); // Debug log
      const response = await apiClient.delete('/discord/disconnect', {
        data: { guild_id: guildId }, // Pass the guild_id to the backend
      });

      alert(response.data.message);
      setConnected(false);
      setServerName('');
      setGuildId(''); // Clear guild_id after disconnection
    } catch (error) {
      console.error('[ERROR] Failed to remove Discord server:', error.response?.data || error.message);
      alert('Failed to remove server connection.');
    }
  };

  useEffect(() => {
    fetchConnectionStatus(); // Fetch connection status when the component mounts
  }, []);

return (
  <>
    <div className="card">
      <div className="discord-server-container">
        <h3 className="discord-server-header">
          {connected ? `Connected: ${serverName}` : 'Connect Your Discord Server'}
        </h3>
        <div className="discord-server-actions">
          {connected ? (
            <button className="discord-button disconnect" onClick={handleDisconnect} disabled={loading}>
              <FontAwesomeIcon icon={faDiscord} className="discord-icon" />
              {loading ? ' Removing...' : ' Remove Server Connection'}
            </button>
          ) : (
            <button className="discord-button connect" onClick={handleConnect} disabled={loading}>
              <FontAwesomeIcon icon={faDiscord} className="discord-icon" />
              {loading ? ' Connecting...' : ' Connect to Discord'}
            </button>
          )}
        </div>
      </div>
    </div>
  </>
);
};

export default DiscordConnect;
