import React, { useState, useEffect } from 'react';
import apiClient from '../../services/apiClient';
import KapperSettledFeed from '../../feeds/Kappers/KapperSettledFeed/KapperSettledFeed';
import TailedFeed from '../../feeds/Subscribers/TailedFeed/TailedFeed';
import './KapperStats.css';

function KapperStats() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const { data } = await apiClient.get('/users/me'); // ✅ Fetch user role
        setUserRole(data.role);
      } catch (error) {
        console.error('Error fetching user role:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserRole();
  }, []);

  if (loading) {
    return <div className="feed-loading">Loading...</div>;
  }

  return (
    <div className="kapperstats-container">
      {userRole === 'pro' ? <KapperSettledFeed /> : <TailedFeed />}

      {showScrollButton && (
        <button
          className="scroll-to-top-button"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          aria-label="Scroll to top"
        >
          ↑
        </button>
      )}
    </div>
  );
}

export default KapperStats;
