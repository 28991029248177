import React, { useEffect, useState } from 'react';
import apiClient from '../../../../services/apiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons'; 
import './AboutMe.css';

function AboutMe() {
  const [aboutTitle, setAboutTitle] = useState('');
  const [about, setAbout] = useState('');
  const [editableTitle, setEditableTitle] = useState('');
  const [editableAbout, setEditableAbout] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      alert('Please log in to view your profile.');
      return;
    }

    apiClient.get('/users/profile')
      .then((response) => {
        const userData = response.data;
        setAboutTitle(userData.about_title || '');
        setAbout(userData.about || '');
        setEditableTitle(userData.about_title || '');
        setEditableAbout(userData.about || '');
      })
      .catch((error) => {
        console.error('Error fetching about details:', error.response?.data || error.message);
      });
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditableTitle(aboutTitle);
    setEditableAbout(about);
  };

  const handleSaveClick = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      await apiClient.put('/users/profile', { about_title: editableTitle, about: editableAbout });
      setAboutTitle(editableTitle);
      setAbout(editableAbout);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving about details:', error.response?.data || error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="about-me-container">
      {/* Top-right Edit, Save, and Cancel buttons */}
      <div className="about-buttons">
        {isEditing ? (
          <>
            <button className="about-cancel-button" onClick={handleCancelClick}>
              Cancel
            </button>
            <button className="about-save-button" onClick={handleSaveClick} disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </button>
          </>
        ) : (
          <FontAwesomeIcon icon={faPen} className="edit-icon-about" onClick={handleEditClick} />
        )}
      </div>

      <div className="about-form">
        {isEditing ? (
          <>
            <input
              type="text"
              id="title"
              className="about-title-input"
              value={editableTitle}
              onChange={(e) => setEditableTitle(e.target.value)}
              placeholder="Title"
            />
            <textarea
              id="body"
              className="about-textarea"
              value={editableAbout}
              onChange={(e) => setEditableAbout(e.target.value)}
              placeholder="About Me"
            ></textarea>
          </>
        ) : (
          <>
            <div className="about-static">
              <h3 className="about-title">{aboutTitle}</h3>
              <p className="about">{about}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AboutMe;
