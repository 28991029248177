import React, { useEffect, useState } from 'react';
import apiClient from '../../../services/apiClient';
import KapperPostCard from '../../../components/cards/KapperPostCard/KapperPostCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed } from '@fortawesome/free-solid-svg-icons';
import './KapperFeed.css';

function KapperFeed() {
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disappearingPosts, setDisappearingPosts] = useState([]); // Track disappearing posts

  // ✅ Double-tap to settle post
  const handleMarkAsSettled = async (postId) => {
    try {
      console.log(`[DEBUG] Marking post ${postId} as settled`);
      setDisappearingPosts((prev) => [...prev, postId]); // Start animation

      setTimeout(async () => {
        await apiClient.post('/reactions/markAsSettled', { post_id: postId });
        console.log(`[DEBUG] Successfully settled post ${postId}`);

        // Remove post after animation
        setFeedItems((prevItems) => prevItems.filter((item) => item.id !== postId));
        setDisappearingPosts((prev) => prev.filter((id) => id !== postId));
      }, 300); // Match CSS animation duration
    } catch (error) {
      console.error(`[ERROR] Failed to settle post ${postId}:`, error.response?.data || error.message);
    }
  };

  // ✅ Fetch only Kapper's own unsettled posts
  const fetchFeedData = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get('/feed/kapperFeed');
      console.log('[DEBUG] Kapper Feed Response:', response.data);
      setFeedItems(response.data);
    } catch (err) {
      console.error('[ERROR] Failed to fetch kapper feed:', err.response?.data || err.message);
      setError('Failed to load feed. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedData();
  }, []);

  // ✅ Group posts into "Today", "Yesterday", etc.
  const renderFeedItems = () => {
    const groupedItems = feedItems.reduce((acc, item) => {
      const itemDate = new Date(item.created_at);
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);

      let groupLabel;
      if (itemDate.toDateString() === today.toDateString()) {
        groupLabel = 'Today';
      } else if (itemDate.toDateString() === yesterday.toDateString()) {
        groupLabel = 'Yesterday';
      } else {
        groupLabel = itemDate.toLocaleDateString();
      }

      if (!acc[groupLabel]) acc[groupLabel] = [];
      acc[groupLabel].push(item);
      return acc;
    }, {});

    return Object.entries(groupedItems).map(([label, items]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {items.map((post) => (
          <KapperPostCard
            key={post.id}
            post={post}
            onMarkAsSettled={handleMarkAsSettled} // ✅ Double-tap to settle
            isDisappearing={disappearingPosts.includes(post.id)} // ✅ Pass animation state
          />
        ))}
      </div>
    ));
  };

  if (loading) {
    return <div className="feed-loading">Loading...</div>;
  }

  if (error) {
    return <div className="feed-error">{error}</div>;
  }

  if (feedItems.length === 0) {
    return (
      <div className="feed-container">
        <div className="feed-empty">
No active slips.
        </div>
      </div>
    );
  }

  return <div className="feed-container">{renderFeedItems()}</div>;
}

export default KapperFeed;
