import React, { useState, useEffect } from 'react';
import apiClient from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import './CreatePost.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faTimes, faReceipt } from '@fortawesome/free-solid-svg-icons';

function CreatePost({ refreshFeed }) {
  const [units, setUnits] = useState(0.25);
  const [odds, setOdds] = useState();
  const [league, setLeague] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [message, setMessage] = useState('');
  const [kapperId, setKapperId] = useState(null);
  const [error, setError] = useState('');
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const navigate = useNavigate();

  const calculateUnitsBorderColor = (units) => {
    const minUnits = 0;
    const maxUnits = 10;
    const green = [43, 176, 43];
    const yellow = [255, 255, 0];
    const red = [236, 0, 0];

    if (units <= 5) {
      const ratio = units / 5;
      const color = green.map((component, index) =>
        Math.round(component + ratio * (yellow[index] - component))
      );
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    } else {
      const ratio = (units - 5) / 5;
      const color = yellow.map((component, index) =>
        Math.round(component + ratio * (red[index] - component))
      );
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }
  };

  const leagues = [
    { name: 'NFL', emoji: '🏈' },
    { name: 'NBA', emoji: '🏀' },
    { name: 'MLB', emoji: '⚾' },
    { name: 'NHL', emoji: '🏒' },
    { name: 'NCAAF', emoji: '🎓🏈' },
    { name: 'BOXING', emoji: '🥊' },
    { name: 'SOCCER', emoji: '⚽' },
    { name: 'HORSES', emoji: '🏇' },
    { name: 'PINGPONG', emoji: '🏓' },
    { name: 'F1', emoji: '🏎️' },
    { name: 'UFC', emoji: '🥋' },
    { name: 'NCAAB', emoji: '🎓🏀' },
    { name: 'PGA', emoji: '⛳' },
    { name: 'TENNIS', emoji: '🎾' },
    { name: 'NASCAR', emoji: '🏁' },
    { name: 'WNBA', emoji: '👩🏽🏀' },
    { name: 'CFL', emoji: '🇨🇦🏈' },
    { name: 'Cricket', emoji: '🏏' },
    { name: 'Esports', emoji: '🎮' },
    { name: 'OTHER', emoji: '🏅' },
  ];

  const half = Math.ceil(leagues.length / 2);
  const leagueRow1 = leagues.slice(0, half);
  const leagueRow2 = leagues.slice(half);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await apiClient.get('/users/profile');
        const userData = response.data;

        if (!userData.kapper_id) {
          alert('You must be a kapper to create a post.');
          navigate('/');
          return;
        }

        setKapperId(userData.kapper_id);
      } catch (error) {
        console.error('Error fetching profile:', error.response?.data || error.message);
        alert('Error fetching profile. Please try again later.');
        navigate('/');
      }
    };

    fetchUserProfile();
  }, [navigate]);

const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setMessage(''); // Clear any previous messages

  if (isSubmitting) return;
  setIsSubmitting(true);

  // Validate odds
  if (!odds || isNaN(odds)) {
    setError('Slip must have valid odds.');
    setIsSubmitting(false);
    return;
  }

  // Validate league selection
  if (!league) {
    setError('You must select a league.');
    setIsSubmitting(false);
    return;
  }

  // Validate screenshot
  if (!image) {
    setError('You must upload a screenshot.');
    setIsSubmitting(false);
    return;
  }

  if (!kapperId) {
    alert('Kapper ID not found. Please log in again.');
    setIsSubmitting(false);
    return;
  }

  try {
    const formData = new FormData();
    formData.append('kapper_id', kapperId);
    formData.append('units', units.toFixed(2));
    formData.append('odds', odds); // Include validated odds
    formData.append('league', league);
    formData.append('analysis', analysis);
    formData.append('image', image); // Screenshot is required

    const response = await apiClient.post('/posts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Show success message and reset the interface
    setMessage(response.data.message || 'Slip posted successfully!');
    setUnits(0.25);
    setOdds(0);
    setLeague('');
    setAnalysis('');
    setImage(null);
    setPreview(null); // Clear the image preview
    setShowAnalysis(false);

    if (refreshFeed) {
      refreshFeed();
    }
  } catch (error) {
    console.error('Error creating post:', error.response?.data || error.message);
    setError('Failed to create post. Please try again.');
  } finally {
    setIsSubmitting(false);
  }
};

  return (
    <div className="create-post-container">
<div className="create-post-open-button"
    onClick={() => setIsFormVisible(!isFormVisible)}
>
  <span className="create-post-button-text">New Slip</span>
{isFormVisible && (
  <div className="odds-textbox-group">
    <input
      type="text"
      id="odds"
      value={odds}
      onChange={(e) => setOdds(e.target.value)}
      className="odds-textbox"
      placeholder="Odds"
    />
          </div>
           )}
        </div> {/* <-- Close the div properly */}

{isFormVisible && (
      <div className="create-post-content">
        <form onSubmit={handleSubmit}>
          <div className="slider-container">
            <div className="slider-group">
              <label htmlFor="units">{`${units.toFixed(2)} ${units === 1 ? 'Unit' : 'Units'}`}</label>
              <input
                type="range"
                id="units"
                value={units}
                onChange={(e) => setUnits(parseFloat(e.target.value))}
                className="slider"
                min="0.25"
                max="10"
                step="0.25"
                style={{ '--thumb-color': calculateUnitsBorderColor(units) }}
              />
            </div>
          </div>
<div className="create-post-league-container">
  <div className="create-post-league-row">
    {leagueRow1.map((lg) => (
      <button
        type="button"
        key={lg.name}
        onClick={() => setLeague(lg.name)}
        className={`create-post-league-button ${
          league === lg.name ? 'selected' : ''
        }`}
      >
        {lg.name} {lg.emoji}
      </button>
    ))}
  </div>
  <div className="create-post-league-row">
    {leagueRow2.map((lg) => (
      <button
        type="button"
        key={lg.name}
        onClick={() => setLeague(lg.name)}
        className={`create-post-league-button ${
          league === lg.name ? 'selected' : ''
        }`}
      >
        {lg.name} {lg.emoji}
      </button>
    ))}
  </div>
</div>
<div className="upload-image-container">
  {preview ? (
    <div className="image-preview">
      <img src={preview} alt="Preview" className="uploaded-image" />
      <button
        type="button"
        className="clear-image-button"
        onClick={() => {
          setPreview(null);
          setImage(null);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  ) : (
    <label htmlFor="slip-image" className="upload-label">
      <div className="upload-icon">
        <FontAwesomeIcon icon={faArrowUpFromBracket} size="3x" />
      </div>
      <p className="upload-text">Tap to Attach Screenshot</p>
    </label>
  )}
<input
  type="file"
  id="slip-image"
  accept="image/*"
  onChange={(e) => {
    const file = e.target.files[0];
    setImage(file);
    setPreview(file ? URL.createObjectURL(file) : null);
  }}
  className="upload-input"
/>

</div>
{!showAnalysis ? (
  <p className="note-toggle" onClick={() => setShowAnalysis(true)}>
    *Add note
  </p>
) : (
  <div className="section">
    <textarea
      id="analysis"
      value={analysis}
      onChange={(e) => {
        if (e.target.value.length <= 100) {
          setAnalysis(e.target.value);
        }
      }}
      className="analysis-textarea"
      placeholder="Additional notes"
    ></textarea>
    <div className="character-counter">{analysis.length}/100</div>
    <p
      className="note-toggle-hide"
      onClick={() => {
        setShowAnalysis(false);
        setAnalysis(''); // Clear the analysis content
      }}
    >
      *Drop note
    </p>
  </div>
)}

<button
  type="submit"
  className="create-post-modal-button"
  disabled={isSubmitting}
>
  {isSubmitting ? 'Posting...' : 'Post Slip'}
          </button>
          
{message && <p className="alert-info">{message}</p>}
{error && <p className="alert-danger">{error}</p>}
<div className="disclaimer">* Slips cannot be edited or removed once posted</div>


        </form>
        </div>
        )}
    </div>
  );
}


export default CreatePost;
