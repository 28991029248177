import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import apiClient from '../../../services/apiClient';
import './About.css';

function About({ openLoginModal }) {
  const { username } = useParams();
  const [aboutTitle, setAboutTitle] = useState('');
  const [about, setAbout] = useState('');
  const [isDiscordConnected, setIsDiscordConnected] = useState(false);
  const [kapperDetails, setKapperDetails] = useState(null);
  const [dailyPostAverage, setDailyPostAverage] = useState(null);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentUsername, setCurrentUsername] = useState(null);

  useEffect(() => {
    // Fetch About Info
    apiClient
      .get(`/kappers/${username}`)
      .then((response) => {
        setAboutTitle(response.data.about_title || '');
        setAbout(response.data.about || 'No additional information provided.');
        setKapperDetails(response.data);
      })
      .catch((error) => {
        console.error('Error fetching about details:', error.response?.data || error.message);
      });

    // Check Discord Status
    apiClient
      .get(`/kappers/status/${username}`)
      .then((response) => {
        setIsDiscordConnected(response.data.connected);
      })
      .catch((error) => {
        console.error('Error fetching Discord status:', error.response?.data || error.message);
      });

    // Fetch Daily Post Average
    apiClient
      .get(`/stats/kappers/stats/${username}`)
      .then((response) => {
        if (response.data.success && response.data.stats.avgPostsPerDay !== undefined) {
          setDailyPostAverage(parseFloat(response.data.stats.avgPostsPerDay).toFixed(1));
        } else {
          console.warn('[DEBUG] avgPostsPerDay missing in response:', response.data);
          setDailyPostAverage('0');
        }
      })
      .catch((error) => {
        console.error('Error fetching daily post average:', error.response?.data || error.message);
        setDailyPostAverage('0'); // Ensure UI doesn't break
      });

    // Check Subscription and Following Status
    const token = localStorage.getItem('accessToken');
    if (token) {
      apiClient
        .get('/users/profile')
        .then((profileResponse) => {
          setCurrentUsername(profileResponse.data.username);
        })
        .catch((error) => console.error('Error fetching current user details:', error.response?.data || error.message));

      apiClient
        .get('/following/followers')
        .then((response) => {
          const followers = response.data?.data || [];
          setIsFollowed(followers.some((follower) => follower.kapper_username === username));
        })
        .catch((error) => console.error('Error fetching followers:', error.response?.data || error.message));

      apiClient
        .get('/subscription/subscriptions')
        .then((response) => {
          const subscriptions = response.data || [];
          setIsSubscribed(subscriptions.some((subscription) => subscription.username === username));
        })
        .catch((error) => console.error('Error fetching subscriptions:', error.response?.data || error.message));
    }
  }, [username]);

  // Log daily post average for debugging
  useEffect(() => {
    console.log('[DEBUG] dailyPostAverage received in frontend:', dailyPostAverage);
  }, [dailyPostAverage]);

  // Follow / Unfollow Logic
  const handleFollow = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return openLoginModal('login');

    try {
      await apiClient.post('/following/follow', { username });
      setIsFollowed(true);
    } catch (error) {
      console.error('Error following kapper:', error.response?.data || error.message);
    }
  };

  const handleUnfollow = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return openLoginModal('login');

    try {
      await apiClient.post('/following/unfollow', { username });
      setIsFollowed(false);
    } catch (error) {
      console.error('Error unfollowing kapper:', error.response?.data || error.message);
    }
  };

  // Subscribe Logic
  const handleSubscribe = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) return openLoginModal('login');

    try {
      await apiClient.post('/subscription/subscribe', { username });
      setIsSubscribed(true);
    } catch (error) {
      console.error('Error subscribing:', error.response?.data || error.message);
    }
  };

  if (!kapperDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="about-me-container">
      {/* Show Discord Badge in Top Right if connected */}
      {isDiscordConnected && (
        <div className="discord-badge">
          <FontAwesomeIcon icon={faDiscord} className="discord-badge-icon" />
          <span>Private Discord Included</span>
        </div>
      )}

      <div className="about-form">
        <div className="about-static">
          <h3 className="about-title">{aboutTitle}</h3>

          {/* Daily Post Average */}
          <p className="daily-post-average">~{dailyPostAverage} slips/day on average</p>

          <p className="about">{about}</p>
        </div>
      <hr className="universal-divider-line" />
        {/* Follow / Subscribe Buttons Below About Section */}
        {currentUsername !== kapperDetails.username ? (
          kapperDetails.role === 'pro' ? (
            <div className="subscribe-button-container">
              <button
                className={`subscribe-button ${isSubscribed ? 'subscribed' : ''}`}
                onClick={!isSubscribed ? handleSubscribe : null}
                disabled={isSubscribed}
              >
                {isSubscribed ? 'Current Member' : 'Join Community'}
              </button>
            </div>
          ) : (
            <div className="follow-button-container">
              <button
                className={`follow-button ${isFollowed ? 'followed' : ''}`}
                onClick={isFollowed ? handleUnfollow : handleFollow}
              >
                {isFollowed ? 'Unfollow' : 'Follow'}
              </button>
            </div>
          )
        ) : (
          <div className="blank-space"></div>
        )}
      </div>
    </div>
  );
}

export default About;
