import React, { useState, useEffect } from 'react';
import apiClient from '../../../services/apiClient';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './LoginRegisterModal.css';

function LoginRegisterModal({ mode = 'login', closeModal }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(mode === 'login');
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    emailOrUsername: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Capture the current page or fallback to home
  const redirectTo = location.state?.from || location.pathname || '/home';

  useEffect(() => {
    setIsLogin(mode === 'login');
  }, [mode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePassword = (password) =>
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!isLogin) {
      if (formData.username.length < 3 || formData.username.length > 20) {
        validationErrors.username = 'Username must be between 3 and 20 characters.';
      }
      if (!validateEmail(formData.email)) {
        validationErrors.email = 'Please enter a valid email address.';
      }
      if (!validatePassword(formData.password)) {
        validationErrors.password =
          'Password must be at least 8 characters long, include a number and a special character.';
      }
      if (formData.password !== formData.confirmPassword) {
        validationErrors.confirmPassword = 'Passwords do not match.';
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const endpoint = isLogin ? '/auth/login' : '/auth/register';
      const payload = isLogin
        ? { emailOrUsername: formData.emailOrUsername, password: formData.password }
        : {
            username: formData.username,
            email: formData.email,
            password: formData.password,
          };

      const response = await apiClient.post(endpoint, payload);

      if (isLogin) {
        const { accessToken, user } = response.data;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('username', user.username);
        localStorage.setItem('role', user.role);

        closeModal();

        // Redirect to the previous page or home
        navigate(redirectTo);
      } else {
        setFormData({ username: '', email: '', password: '', confirmPassword: '' });
        setIsLogin(true);
      }
    } catch (error) {
      setErrors({
        general: error.response?.data?.message || 'An error occurred. Please try again.',
      });
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const { credential } = credentialResponse;
      const response = await apiClient.post('/auth/google-login', { token: credential });

      const { accessToken, user } = response.data;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('username', user.username);
      localStorage.setItem('role', user.role);

      closeModal();
      navigate(redirectTo);
    } catch (error) {
      setErrors({ general: 'Failed to log in with Google.' });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-box">
        <img src="/k.png" alt="Logo" className="modal-logo" />
        <button className="close-icon" onClick={closeModal}>
          &times;
        </button>
        <div className="modal-content">
          <h2>{isLogin ? <strong>Welcome Back</strong> : <strong>Get Started</strong>}</h2>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => alert('Google Login failed')}
            className="google-login-button"
            text={isLogin ? 'Sign in with Google' : 'Sign up with Google'}
          />
          <div className="separator">
            <span>or</span>
          </div>
          <form onSubmit={handleSubmit} className="form">
            {!isLogin && (
              <>
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                {errors.username && <p className="error-message">{errors.username}</p>}
              </>
            )}
            {isLogin ? (
              <input
                type="text"
                name="emailOrUsername"
                placeholder="Email or Username"
                value={formData.emailOrUsername}
                onChange={handleChange}
                required
              />
            ) : (
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            )}
            {errors.email && <p className="error-message">{errors.email}</p>}
            <div className="password-input-container">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="toggle-password-visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </button>
            </div>
            {errors.password && <p className="error-message">{errors.password}</p>}
            {!isLogin && (
              <>
                <div className="password-input-container">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    className="toggle-password-visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                  </button>
                </div>
                {errors.confirmPassword && (
                  <p className="error-message">{errors.confirmPassword}</p>
                )}
              </>
            )}
            <button type="submit" className="form-submit-button">
              {isLogin ? 'Login' : 'Register'}
            </button>
          </form>
          {errors.general && <p className="error-message">{errors.general}</p>}
          <button
            className="toggle-form-button"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? 'Need to Register?' : 'Already have an account? Login'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginRegisterModal;
