import React from 'react';
import './LandingPage.css';

function LandingPage() {
  return (
    <div>
      {/* Gradient Top Section */}
      <div className="landing-header-section">
        <h1 className="landing-header">Kapper.io Coming Soon</h1>
        <p className="landing-subheader">
        </p>
      </div>

      {/* White Section Below */}
      <div className="landing-content-section">
      </div>
    </div>
  );
}

export default LandingPage;
