import React from 'react';
import './ReactionBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

const ReactionBar = ({ totalPlays, onReact, userReacted, isSettled }) => {
  return (
    <div className="reaction-bar-container" onDoubleClick={onReact}>
      {/* Status Indicator (Live/Settled) */}
      <div className="status-text">
        <FontAwesomeIcon 
          icon={isSettled ? faCircleCheck : faCircle} 
          className={`status-icon ${isSettled ? 'settled' : 'Live'}`} 
        />
        <span> {isSettled ? 'Settled' : 'Live'}</span>
      </div>

      {/* Reaction Count & Icon */}
      <div className={`reaction-totals ${userReacted ? 'reacted' : 'unreacted'}`}>
        <FontAwesomeIcon icon={faArrowsRotate} className="reaction-icon" />
        <span className="total-play">
          {userReacted
            ? `${totalPlays} Tailing`
            : `${totalPlays} Tailing`}
        </span>
      </div>
    </div>
  );
};

export default ReactionBar;
