import React, { useEffect, useState, useCallback, useMemo } from 'react';
import apiClient from '../../../services/apiClient';
import FeedPostCard from '../../../components/cards/FeedPostCard/FeedPostCard';
import './TailedFeed.css';

function TailedFeed() {
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bankroll, setBankroll] = useState(null);

  useEffect(() => {
    const fetchBankroll = async () => {
      try {
        const { data } = await apiClient.get('/stats/users/bankroll');
        setBankroll(data.bankroll || null);
      } catch (err) {
        console.error('Error fetching bankroll:', err.message);
        setBankroll(null);
      }
    };

    const fetchFeed = async () => {
      try {
        const { data } = await apiClient.get('/feed/tailed-feed'); // ✅ Fetch tailed posts
        const posts = data.posts || [];

        const enrichedPosts = posts.map((post) => ({
          ...post,
          image: post.image || null,
          total_plays: Number(post.total_plays),
          user_reaction: post.user_reaction !== null ? Number(post.user_reaction) : 0, // ✅ Ensure userReaction
          is_tailed: post.user_reaction === 1, // ✅ Mark as "tailed"
        }));

        setFeed(enrichedPosts);
      } catch (err) {
        console.error('Error fetching tailed feed:', err.message);
        setError('Failed to load tailed feed. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBankroll();
    fetchFeed();
  }, []);

  const groupedFeed = useMemo(() => {
    return Object.entries(
      feed.reduce((acc, item) => {
        const itemDate = new Date(item.created_at);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        let groupLabel =
          itemDate.toDateString() === today.toDateString()
            ? 'Today'
            : itemDate.toDateString() === yesterday.toDateString()
            ? 'Yesterday'
            : itemDate.toLocaleDateString();

        if (!acc[groupLabel]) acc[groupLabel] = [];
        acc[groupLabel].push(item);
        return acc;
      }, {})
    );
  }, [feed]);

  const renderPostGroups = () => {
    return groupedFeed.map(([label, items]) => (
      <div key={label} className="feed-post-group">
        <div className="feed-post-divider">
          <hr className="divider-line" />
          <span className="divider-text">{label}</span>
          <hr className="divider-line" />
        </div>
        {items.map((post) => (
          <FeedPostCard
            key={post.post_id}
            post={post}
            bankroll={bankroll}
            userReacted={post.is_tailed} // ✅ Pass to FeedPostCard
          />
        ))}
      </div>
    ));
  };

  if (loading) return <div className="feed-loading">Loading...</div>;
  if (error) return <div className="feed-error">{error}</div>;
  if (feed.length === 0)
    return (
      <div className="feed-container">
        <div className="feed-empty">
          <div className="feed-empty-text">No settled tailed slips.</div>
        </div>
      </div>
    );

  return <div className="feed-container">{renderPostGroups()}</div>;
}

export default TailedFeed;
