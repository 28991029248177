import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../../../services/apiClient';
import './Profile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons'; 

const STATIC_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_STATIC_BASE_URL : 'http://localhost:5000';
const MAX_BIO_LENGTH = 50; // Short bio limit

function Profile() {
  const [user, setUser] = useState(null);
  const [kapperData, setKapperData] = useState(null);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      alert('Please log in to view your profile.');
      navigate('/login');
      return;
    }

    apiClient.get('/users/profile')
      .then((response) => {
        const userData = response.data;

        if (!userData.kapper_id) {
          navigate('/');
          return;
        }

        const validatedUserData = {
          ...userData,
          follower_count: userData.follower_count || 0,
          following_count: userData.following_count || 0,
          subscriber_count: userData.subscriber_count || 0,
          leagues: Array.isArray(userData.leagues) ? userData.leagues : [],
          banner_picture: userData.banner_picture || null,
          profile_picture: userData.profile_picture || null,
          display_name: userData.display_name || userData.username || 'User',
          bio: userData.bio || '',
        };

        setUser(validatedUserData);
        setKapperData({
          banner_picture_path: validatedUserData.banner_picture,
          profile_picture_path: validatedUserData.profile_picture,
          banner_picture_preview: null,
          profile_picture_preview: null,
          bio: validatedUserData.bio,
          display_name: validatedUserData.display_name,
        });
        setSubscriberCount(validatedUserData.subscriber_count);
        setLeagues(validatedUserData.leagues);
      })
      .catch((error) => {
        console.error('Error fetching profile:', error.response?.data || error.message);
        navigate('/');
      });
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'display_name' && value.length <= 12) {
      setKapperData((prevData) => ({ ...prevData, [name]: value }));
    }

    if (name === 'bio' && value.length <= MAX_BIO_LENGTH) {
      setKapperData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const previewURL = URL.createObjectURL(file);

      setKapperData((prevData) => ({
        ...prevData,
        [`${name}_preview`]: previewURL,
        [`${name}_file`]: file,
      }));

      return () => URL.revokeObjectURL(previewURL);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const formData = new FormData();
      if (kapperData.banner_picture_file) formData.append('banner_picture', kapperData.banner_picture_file);
      if (kapperData.profile_picture_file) formData.append('profile_picture', kapperData.profile_picture_file);
      formData.append('bio', kapperData.bio || '');
      formData.append('display_name', kapperData.display_name || user.display_name || '');

      await apiClient.put('/users/profile', formData);
      const response = await apiClient.get('/users/profile');
      const userData = response.data;

      setUser({
        ...userData,
        followers_count: userData.followers_count || 0,
        following_count: userData.following_count || 0,
      });

      setKapperData({
        banner_picture_path: userData.banner_picture,
        profile_picture_path: userData.profile_picture,
        display_name: userData.display_name || userData.username,
        bio: userData.bio,
        banner_picture_preview: null,
        profile_picture_preview: null,
      });

      setSubscriberCount(userData.subscriber_count || 0);
      setLeagues(userData.leagues || []);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error.response?.data || error.message);
    } finally {
      setIsSaving(false);
    }
  };

  if (!user) return <div>Loading...</div>;

  return (
    <div className="profile">
      <div className="card profile-container">
        <div
          className={`banner editable ${isEditing ? 'is-editing' : ''}`}
          onClick={() => isEditing && document.querySelector("input[name='banner_picture']").click()}
        >
          <img
            src={kapperData.banner_picture_preview || (kapperData.banner_picture_path ? `${STATIC_URL}/${kapperData.banner_picture_path}` : '/banner.png')}
            className="banner-image"
            alt="Banner"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/banner.png';
            }}
          />
          <div className="editable-overlay banner-overlay">
            <FontAwesomeIcon icon={faPen} className="edit-icon" />
          </div>
          <input type="file" name="banner_picture" style={{ display: 'none' }} onChange={handleFileChange} />
        </div>
        <div
          className={`profile-picture-container editable ${isEditing ? 'is-editing' : ''}`}
          onClick={() => isEditing && document.querySelector("input[name='profile_picture']").click()}
        >
          <img
            src={kapperData.profile_picture_preview || (kapperData.profile_picture_path ? `${STATIC_URL}/${kapperData.profile_picture_path}` : '/profile.png')}
            className="profile-picture"
            alt="Profile Picture"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/profile.png';
            }}
          />
          <div className="editable-overlay profile-picture-overlay">
            <FontAwesomeIcon icon={faPen} className="edit-icon" />
          </div>
          <input type="file" name="profile_picture" style={{ display: 'none' }} onChange={handleFileChange} />
        </div>
        <div className="stats-row">
          {user.role === 'pro' ? (
            <p className="subscribers">{subscriberCount || 0} Subscribers</p>
          ) : (
            <p className="followers-following">{user.followers_count || 0} Followers</p>
          )}
        </div>
        <h1 className="username">
          @{user.username}
          {!isEditing ? (
            <FontAwesomeIcon icon={faPen} className="edit-icon-large" onClick={() => setIsEditing(true)} />
          ) : (
            <div className="button-group">
              <button className="universal-cancel-button" onClick={() => setIsEditing(false)}>Cancel</button>
              <button className="universal-save-button" onClick={handleSave} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </h1>
        {isEditing ? (
          <>
            <input
              type="text"
              className="display-name-edit"
              value={kapperData.display_name || ''}
              name="display_name"
              maxLength={12}
              onChange={handleChange}
              placeholder="Enter display name"
            />
            <small className="char-count">{kapperData.display_name?.length || 0}/12</small>
          </>
        ) : (
          <p className="display-name">{user.display_name}</p>
        )}
        {isEditing ? (
          <>
            <textarea
              className="bio-edit"
              name="bio"
              value={kapperData.bio}
              onChange={handleChange}
              style={{ height: 'auto', minHeight: '50px' }}
              rows={Math.min(10, kapperData.bio.split('\n').length + 1)}
            />
            <small className="char-count">{kapperData.bio.length}/{MAX_BIO_LENGTH}</small>
          </>
        ) : (
          <p className="bio">{kapperData.bio}</p>
        )}
        <div className="leagues-section">
          <div className="selected-leagues">
            {Array.isArray(leagues) && leagues.length > 0 ? (
              leagues.map((league, index) => (
                <button key={index} className="league-button selected" disabled>{league}</button>
              ))
            ) : (
              <p>No leagues selected</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;